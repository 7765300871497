@import "custom-antd";
@app-layout-sidebar-width: 200px;
.AppLayout{
  width: 100vw;
  height: 100vh;
  overflow:hidden;
  background: white;
  display: flex;
  .AppLayoutMain{
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .AppLayoutHeader{
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      background: #FDFEFE;
      box-shadow: 0 8px 16px -12px rgba(27,58,146,0.16);
      height: 80px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      justify-content: space-between;
      .AppLayoutHeaderMain{
        width: 100%;
      }
    }
    .AppLayoutMainBody{
      padding: 20px;
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      overflow: hidden;
      bottom: 0;
      overflow-y: auto;
    }
  }

  .AppLayoutSideBar{
    width: @app-layout-sidebar-width;
    height: 100vh;
    overflow: auto;
    flex-shrink: 0;
    border-right: 1px solid #e4f0fb;
    background: #FDFEFE;
    .AppLayoutSideBarLogo{
      margin-top: 25px;
      margin-left: 20px;
      img{
        width: 42px;
        height: 42px;
      }
      h2{
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 0;
      }
      small{
        font-size: 12px;
        line-height: 17px;
      }
    }
    .AppLayoutSideBarItems{
      padding: 60px 12px;
      .AppLayoutSideBarItem{
        height: 48px;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        margin-bottom: 12px;
        .massa{
          font-size: 24px;
          margin-right: 12px;
        }
        span{
          font-weight: bold;
        }
      }
      .LayoutSideBarSubItem{
        height: 32px;
        font-size: 14px;
        padding-left: 48px;
      }
    }
  }
}
