.LoginPage{
  background: url("https://geestack-public.oss-cn-shanghai.aliyuncs.com/Oxiracetam/login-background.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .LoginPageMask{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.30);
  }
  .LoginLogo{
    width: 480px;
    margin-right: 0.11 * 100vw;
    z-index: 1;
  }
  .LoginPanel{
    z-index: 1;
    background: #FDFEFE;
    box-shadow: 12px 8px 44px -12px rgba(27,58,146,0.16);
    border-radius: 20px;
    width: 480px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    .LoginForm{
      width: 75%;
      .LoginTitle{
        margin-top: 0;
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 20px;
        font-weight: bold;
      }
    }
  }
}
