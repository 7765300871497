@import '~antd/dist/antd.less';
@import 'custom-antd.less';
@import 'custom-antd-class.less';

@font-face {
  font-family: "SF-Pro-Display";
  src: url("https://geestack-public.oss-cn-shanghai.aliyuncs.com/fonts/fonts/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("https://geestack-public.oss-cn-shanghai.aliyuncs.com/fonts/fonts/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "SourceHanSansSC";
  src: url("https://geestack-public.oss-cn-shanghai.aliyuncs.com/fonts/fonts/SourceHanSansSC-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "SourceHanSansSC";
  src: url("https://geestack-public.oss-cn-shanghai.aliyuncs.com/fonts/fonts/SourceHanSansSC-Medium.otf") format("opentype");
  font-weight: bold;
}


body {
  font-family: "SourceHanSansSC", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
  background: @table-header-bg;
}

::-webkit-scrollbar-thumb {
  background-color: fade(@table-border-color, 50%);
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: @table-border-color;
  }
}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #333;
}

.massa-selectable-item {
  cursor: pointer;
  color: @text-color-secondary;
  border-radius: @border-radius-base;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 5px 10px;
  user-select: none;
  &:hover {
    background: @background-color-light;
    color: rgba(6, 118, 237, 0.6);
  }

  &:active {
    color: @primary-color;
    background: @table-selected-row-bg;
  }

  &-active {
    color: @primary-color;
    background: @table-selected-row-bg;

    &:hover {
      color: @primary-color;
      background: @table-selected-row-bg;
    }
  }
}

.massa-search {
  background: #FAFCFD;

  .ant-input-lg {
    font-size: 14px;
  }

  .ant-input-prefix {
    padding-left: 5px;
    margin-right: 8px;
    //margin-top: -5px;
    //margin-bottom: -5px;
    .massa {
      //font-size: 24px;
      color: @text-color-secondary;
    }
  }
}

.massa-close-icon {
  font-size: 32px !important;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: @text-color-secondary;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.massa-hover-icon {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: @text-color-secondary;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.massa-slicing-part {
  padding: 15px;
  border-radius: 4px;
  border: 1px solid @border-color-base;
  display: flex;
  flex-wrap: wrap;
  width: 660px;
  height: 360px;
  align-content: flex-start;
  overflow-y: auto;

  .massa-slicing-part-block {
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 4px;
    background: @border-color-base;
  }

  .massa-slicing-part-block-uploading {
    background: @primary-color;
    opacity: 0.5;
  }

  .massa-slicing-part-block-finished {
    background: @primary-color;
  }

  .massa-slicing-part-block-error {
    background: @error-color;
    opacity: 0.7;
  }
}

.massa-dialog-check {
  font-size: 220px !important;
  color: @success-color;
  opacity: 0.3;
  position: absolute;
  right: -40px;
  top: -130px;
}

.massa-dialog-subtitle {
  margin-top: -10px;
  margin-bottom: 12px;
}

.massa-dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .ant-btn {
    min-width: 120px;
  }
}

.massa-check-code {
  background: @border-color-base;
  color: @error-color;
  font-weight: bold;
  user-select: none;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  height: @input-height-base;
}

.massa-border-box {
  border: 1px solid #DAE1ED;
  border-radius: 4px;
}


.absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.massa-text-progress {
  position: relative;
  overflow: hidden;

  .progress-1 {
    position: absolute;
    top: 0;
    width: 100%;
    color: @primary-color;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progress-2 {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;
    overflow: hidden;
  }

  .progress-2-inner {
    position: absolute;
    top: 0;
    width: 100%;
    color: @white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-progress {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .ant-progress-inner {
      border-radius: 4px;
      background: @border-color-base;

      .ant-progress-bg {
        border-radius: 4px;
      }
    }
  }
}

.massa-text-progress-exception {
  .progress-1 {
    color: @error-color;
  }
}

.text-primary {
  color: @primary-color;
}

.text-success {
  color: @success-color;
}

.text-error {
  color: @error-color;
}

.text-dark {
  color: @text-color-secondary-dark;
}
