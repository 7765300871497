@font-face {
  font-family: "massa"; /* Project id 2465555 */
  src: url('iconfont.woff2?t=1630481808839') format('woff2'),
       url('iconfont.woff?t=1630481808839') format('woff'),
       url('iconfont.ttf?t=1630481808839') format('truetype');
}

.massa {
  font-family: "massa" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.massa-annotate:before {
  content: "\e618";
}

.massa-tag:before {
  content: "\e61c";
}

.massa-monitor:before {
  content: "\e61d";
}

.massa-log:before {
  content: "\e61e";
}

.massa-user:before {
  content: "\e61f";
}

.massa-statistics:before {
  content: "\e620";
}

.massa-check:before {
  content: "\e61b";
}

.massa-right-caret:before {
  content: "\e61a";
}

.massa-database:before {
  content: "\e619";
}

.massa-share:before {
  content: "\e601";
}

.massa-more:before {
  content: "\e602";
}

.massa-upload:before {
  content: "\e603";
}

.massa-compose:before {
  content: "\e604";
}

.massa-searchFile:before {
  content: "\e605";
}

.massa-list:before {
  content: "\e606";
}

.massa-download:before {
  content: "\e607";
}

.massa-grid:before {
  content: "\e608";
}

.massa-right:before {
  content: "\e609";
}

.massa-play:before {
  content: "\e60a";
}

.massa-delete:before {
  content: "\e60b";
}

.massa-moveTo:before {
  content: "\e60c";
}

.massa-file:before {
  content: "\e60d";
}

.massa-copy:before {
  content: "\e60e";
}

.massa-close:before {
  content: "\e60f";
}

.massa-edit:before {
  content: "\e610";
}

.massa-reset:before {
  content: "\e611";
}

.massa-home:before {
  content: "\e612";
}

.massa-pause:before {
  content: "\e613";
}

.massa-left:before {
  content: "\e614";
}

.massa-search:before {
  content: "\e615";
}

.massa-newFolder:before {
  content: "\e616";
}

.massa-transport:before {
  content: "\e617";
}

